@import '~nicbase-default-theme/lib/variables.scss';

.wrapper {
  @apply flex;

  &:hover {
    .removeWaypoint {
      opacity: 1;
    }
  }

  &:last-child {
    .numberContainer {
      border: none;
    }
  }

  .removeWaypoint {
    @apply cursor-pointer ml-12;
    fill: $color-primary;
    transition: opacity 0.2s linear;
    opacity: 0;
  }

  .numberContainer {
    left: -$spacing-xlarge;
    top: 2.7rem;
    bottom: -2.7rem;
    border-left: 1px dotted $color-palette-grey40;

    .number {
      @apply flex justify-center text-white font-bold;
      background: $color-palette-grey40;
      width: 20px;
      height: 20px;
      align-items: center;
      border-radius: 20px;
      transform: translateX(-50%);
    }
  }
}
