@import "~nicbase-default-theme/lib/variables.scss";

.tourDetails {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: $spacing-xlarge;
  grid-row-gap: $spacing-large;
  margin: $spacing-xxlarge 0;
}

.label {
  font-weight: bold;
}
