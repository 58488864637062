@import "~nicbase-default-theme/lib/variables";

.radioGroup {
  display: flex;
  flex-direction: column;
}

/* Customize the label (the radioButton) */
.radioButton {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 2px 0 2px 2px;
}

/* Hide the browser's default radio button */
.inputElement {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  transition: all 400ms ease;
  background-color: #dadbd8;
  margin-right: 5px;

  &:hover {
    transform: scale(1.1);
  }
}

/* When the radio button is checked, add a blue background */
.inputElement:checked ~ .checkmark {
  background-color: $color-primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/* Show the indicator (dot/circle) when checked */
.inputElement:checked ~ .checkmark:after {
  display: block;
}

.label {
  font-size: 1.4rem;
}

.disabled {
  color: #cccccc;
}

.bold {
  font-weight: $font-weight-bold;
}

.inputElement:disabled {
  ~ .checkmark {
    background: #cccccc;
    &:after {
      background: #999999;
    }
  }
}
