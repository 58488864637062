.autocomplete {
  display: flex;
  align-items: center;
  position: relative;
}
.autocompleteInput {
  flex: 1;

  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 4px;
    width: 0;
    height: 0;
    display: block;
    border-top: 5px solid rgba(0, 0, 0, 0.2);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  input {
    padding-right: 28px;
  }
}
.clearButton {
  @apply w-4 h-4 absolute cursor-pointer transition ease-in duration-300;
  right: 4px;

  &:hover {
    transform: scale(1.2);
  }
  &:active {
    transform: scale(0.98);
  }
}

.suggestionList {
  animation: scale 200ms forwards;
}
@keyframes scale {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
