.tooltip {
  @apply bg-white text-sm absolute z-20 px-2 py-1 shadow-md transform -translate-y-full -translate-x-1/2;
}

.tooltip:after {
  @apply absolute left-1/2 transform -translate-x-1/2 w-0 h-0;
  content: '';
  bottom: -4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid theme('colors.white');
}
