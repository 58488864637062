@import "../../config";

.application-container-full-screen {
  background: #f6f6f6;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 1s ease-in;
}

.desktop-content-area-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
}

.load-spinner-fullscreen-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
}

.content-container-responsive {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;

  &.mobile {
    flex-direction: column;
    overflow-y: auto;
    margin-top: $nav-bar-height;
  }
}

@keyframes faderIn {
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(0px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.faderIn {
  animation-name: faderIn;
}

@keyframes faderOut {
  20% {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.faderOut {
  animation-name: faderOut;
}

.animate {
  animation-duration: 2000ms;
}
