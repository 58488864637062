@import '~nicbase-default-theme/lib/variables';

.navigationItem {
  display: block;
  background-color: $color-palette-white;
  box-shadow: 2px 2px 6px rgba(150, 150, 150, 0.5), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  color: $color-text !important;
  font-size: 2.7rem;
  font-weight: 800;
  padding: 55px 0 50px 30px;
  margin: 15px 10px;
}
