.bottom:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  border-top-color: var(--backgroundColor);
  border-width: 5px;
  margin-left: -5px;
}

.left:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  border-right-color: var(--backgroundColor);
  border-width: 5px;
  margin-top: -5px;
}

.right:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  border-left-color: var(--backgroundColor);
  border-width: 5px;
  margin-top: -5px;
}

.top:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--backgroundColor);
  border-width: 5px;
  margin-left: -5px;
}
