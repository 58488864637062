.container {
  @apply mb-4;

  .removeProject {
    @apply ml-12 opacity-0;
    transition: opacity 0.2s linear;
  }

  &:hover {
    .removeProject {
      @apply opacity-100;
    }
  }
}
