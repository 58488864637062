@import '~nicbase-default-theme/lib/variables';

.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  outline: none;
  cursor: pointer;
  color: $color-primary;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  padding: 1.2rem;
  white-space: nowrap;

  &:not(.active) {
    font-weight: $font-weight-normal;
  }

  &.active {
    font-weight: $font-weight-bold;
  }

  &.disabled {
    color: #cccccc;
    cursor: default;
  }
}

.span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: solid 2px $color-primary;
  transform: scaleX(0);
  transition: transform 300ms ease-in;

  &.active {
    transform: scaleX(1);
  }

  &:not(.active) {
    transform: scaleX(0);
  }
}

.tabs {
  display: flex;
  flex-direction: column;
}
.headers {
  display: flex;
}
.content {
  flex: 1;
}
