@import "~nicbase-default-theme/lib/variables";
$table-border-color: $color-header-border;
.noRowsRenderer {
  display: flex;
  flex: 1;
}

.table-wrapper {
  @apply leading-tight;
  display: flex;
  flex-direction: column;
  flex: 1 100%;
  overflow: hidden;

  .row-count {
    font-size: $font-size-normal;
    color: $color-header-border;
    fill: $color-header-border;
    display: flex;
    margin-bottom: $spacing-small;
    width: 120px;

    .row-count-text {
      margin-top: 5px;
      margin-left: $spacing-medium;
    }

    .table-tool-tip {
      background: #ffffff;
      font-family: $font-family;
      font-size: $font-size-normal;
      font-weight: $font-size-headline1;
      color: $color-header-border;
      border-radius: 3px;
      box-shadow: 2px 2px 6px rgba(150, 150, 150, 0.5), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }

  .row-count-default {
    font-size: $font-size-normal;
    color: $color-header-border;
    fill: $color-header-border;
    display: flex;
    margin-bottom: $spacing-small;
    width: 200px;
  }
}
.nic-table {
  display: flex;
  flex-direction: column;
  flex: 1 100%;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;

  &:hover .nic-table-column,
  &:hover .table-header-column {
    border-color: $color-palette-grey20;

    &:last-child {
      border-color: transparent;
    }
  }

  :focus {
    outline: none;
  }
}

.table-header {
  display: flex;
  position: relative;
  box-shadow: -6px 4px 6px rgba(0, 0, 0, 0.1), -6px 2px 4px rgba(0, 0, 0, 0.06);
  z-index: 1;
}

.table-header-column {
  // font-size: $font-size-headline1;
  // font-weight: $font-size-headline1;
  font-size: 1.4rem;
  background-color: $color-palette-white;
  font-weight: bold;
  color: $color-primary;
  display: flex;
  flex-direction: row;
  border-right: 1px solid transparent;
  align-items: center;
  cursor: pointer;
  position: relative;
  min-width: 0;
  box-sizing: border-box;
  justify-content: flex-start;
  transition: border-color 400ms ease-in;

  .sort-indicator {
    flex: 0 0 10px;
    fill: $color-palette-grey40;
    margin-right: 10px;

    .active {
      fill: $color-primary;
    }
  }

  .ReactVirtualized__Table__headerTruncatedText {
    flex: 1;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px 5px;
  }
}

.table-header-ordering {
  cursor: move;
  background: $color-palette-grey20;
  opacity: 0.8;
}

.nic-table-row {
  font-size: $font-size-text2;
  font-weight: $font-weight-text2;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.table-row-odd {
  background-color: #f0f0f0;
}

.nic-table-column {
  font-size: 1.2rem;
  display: flex;
  padding: 1.2rem;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  border-right: 1px solid transparent;
  box-sizing: border-box;
  transition: border-color 400ms ease-in;

  &:last-child {
    border-right: 1px solid transparent;
    flex-shrink: 1;
  }
}

.table-cell {
  display: flex;
  align-self: center;
}

.resizeHandle {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  z-index: 1;
  cursor: col-resize;

  &:hover,
  &.resizeHandleDragging {
    border-right: 1px solid #aaaaaa;
    background: rgba(0, 0, 0, 0.1);
  }
}

.selection-checkbox-check {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.selection-checkbox-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-self: center;
  margin-bottom: 0;
  height: auto;
}
