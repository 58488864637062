@import "~nicbase-default-theme/lib/variables";

.titleContainer {
  @apply flex pr-6;
  margin-bottom: $spacing-medium;
  margin-top: $spacing-medium;
}
.title {
  color: $color-primary;
  font-size: $font-size-headline1;
  border-bottom: 2px solid transparent;
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;

  &:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    content: " ";
    background: linear-gradient(90deg, $color-primary, rgba($color-primary, 0.05));
  }
}
.titleText {
  cursor: pointer;
  font-weight: bold;

  &:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $color-primary;
    right: -18px;
    top: calc(50% - 3px);
    content: " ";
    position: absolute;
    transition: transform 0.5s;
    transform: rotate(90deg);
  }
}
.editComponent {
  cursor: pointer;
  margin-left: auto;
}
.content {
  transition: max-height 0.5s linear;
  max-height: 0;
  display: none;
  overflow-y: hidden;
  font-size: $font-size-small;

  span,
  input,
  ul,
  li {
    font-size: $font-size-small;
  }
}

.expanded {
  .content {
    display: initial;
    max-height: 100%;
  }
  .titleText {
    &:after {
      transform: rotate(0deg);
    }
  }
}
