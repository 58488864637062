@import '~nicbase-default-theme/lib/variables';

label.settings-item {
  cursor: pointer;
  display: flex;

  input {
    display: none;

    & + span {
      flex: 1;
      display: inline-block;
      text-align: center;
      padding: $spacing-medium;
      border-radius: 3px;
    }

    &:checked + span {
      background-color: $color-palette-grey20;
    }
  }
}
