@import "~nicbase-default-theme/lib/variables";

.list {
  position: absolute;
  width: 100%;
  background: #ffffff;
  list-style: none;
  z-index: 999;
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 4px hsla(0, 0, 0, 0.1);
  animation: scale 200ms forwards;
  transform-origin: top;
  font-size: 1.4rem;
  overflow: auto;
  @apply leading-tight;

  li {
    color: $color-primary;
    padding: 0;
    cursor: pointer;
  }
}

.listItem {
  padding: 5px;
  word-break: break-all;

  &:hover {
    background: hsla(0, 0, 0, 0.1);
  }
}

.selected {
  font-weight: bold;
  background: hsla(0, 0, 0, 0.1);
}

.disabled {
  color: #999999;
}

@keyframes scale {
  from {
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    transform: scaleY(1);
    transform-origin: top;
  }
}
