@import '~nicbase-default-theme/lib/variables';

.tour-board-settings-container {
  .tour-board-settings {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: $spacing-large;
    grid-row-gap: $spacing-large;
  }

  .tour-defaults-container {
    margin-top: $spacing-large;
  }

  .tour-defaults-section-header {
    margin-top: $spacing-large;
  }

  .details-container {
    text-align: left;
  }
  .details-section-header {
    > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
