@import '~nicbase-default-theme/lib/variables';

.route-monitoring-section {
  flex: 1;
  display: grid;
  grid-template-rows: auto auto 1fr;
  justify-items: center;
  grid-gap: $spacing-medium;
  padding: $spacing-medium;

  .monitoring-toggle {
    display: flex;
    align-items: center;

    .selected-option {
      font-weight: bold;
    }
  }

  .route-map {
    width: 100%;
    height: 100%;
    position: relative;
    @apply flex items-center justify-center flex-col;
  }

  .route-monitoring-disabled-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $color-palette-white;
    font-size: $font-size-headline1;
  }
}
