@keyframes overlayFade {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.overlay {
  animation: overlayFade 0.2s ease;
  animation-fill-mode: forwards;
}

@keyframes dialogFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dialog {
  max-height: 90vh;
  max-width: 85vw;
  min-width: 20vw;
  animation: dialogFade 0.2s ease-in-out;
  animation-fill-mode: forwards;
}
