@import "~nicbase-default-theme/lib/variables";
.home {
  @apply bg-gray-100 text-sm leading-normal w-full overflow-auto;

  h1 {
    @apply block text-3xl font-bold my-6 p-0;
  }

  h2 {
    @apply block text-2xl font-bold my-5 p-0;
  }

  h3 {
    @apply block text-lg font-bold my-4 p-0;
  }

  h4 {
    @apply block text-sm font-bold my-4 p-0;
  }

  p {
    @apply block my-3;
  }

  strong {
    @apply font-bold;
  }

  li {
    display: list-item;
    @apply list-disc;
  }

  ul {
    @apply block list-disc my-3 pl-10;
  }

  @media only screen and (max-width: 768px) {
    .wrapper {
      @apply flex flex-col-reverse;
    }
  }
}

@media only screen and (min-width: 768px) {
  .home {
    .wrapper {
      @apply bg-gray-100 h-full flex flex-1 min-h-0;

      .scrollDiv {
        flex: 0.75;
        @apply relative overflow-hidden;

        .wrapper-left {
          margin-right: -18px;
          @apply absolute top-0 left-0 right-0 bottom-0 overflow-y-scroll overflow-x-hidden mb-0;
        }
      }

      .wrapper-right {
        flex: 0.25;
      }
    }
  }
}
