.input {
  @apply relative text-primary;
  font-size: 1.6rem;
  padding: 1em 0;

  &.isDisabled {
    color: #cccccc;
  }

  &.hasError {
    @apply text-red-500;
  }

  > label {
    flex: 1;
    display: flex;
  }
}

.inputElement {
  background-color: transparent;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
  outline: none;
  width: 100%;
  padding: 2px 4px;
  color: inherit;
  font-size: 1em;

  &:focus {
    border-bottom: 1px solid currentColor;
  }
  &:disabled {
    border-bottom: 1px dotted currentColor;
  }
}

.textArea {
  border: 1px solid #cccccc;
}

.label {
  position: absolute;
  left: 4px;
  top: 1em;
  transition: all 400ms ease;
  color: #cccccc;
  font-size: 1em;
  pointer-events: none;
}

.errorIcon {
  position: relative;
  top: 4px;
  right: 18px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.counter {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.7em;
  display: inline-block;
  pointer-events: none;
}

.hasError {
  .label {
    color: inherit;
  }
  .inputElement {
    border-color: currentColor;
  }
}

.filled + .label,
.inputElement:focus + .label {
  transform: translate(0, -0.7em);
  font-size: 0.7em;
  color: inherit;
}

.inputTextArea {
  .filled + .label,
  .inputElement:focus + .label {
    transform: translate(0, -1.2em);
  }
}
