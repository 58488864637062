@import '~nicbase-default-theme/lib/variables';

.error {
  background-color: $color-palette-white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-family: $font-family;
    color: $color-primary;
    text-align: left;

    .header {
      font-size: $font-size-large;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .content {
      margin-bottom: 30px;
    }
  }
}
