@import "~nicbase-default-theme/lib/variables";

.listItem {
  @apply py-1 px-2;
  list-style-type: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.disabled {
  color: $color-grey-light;
}
