.appBar {
  display: flex;
  flex: 0 0 36px;
  background: #dadbd8;
  background: var(--color-background-nav-bar-top, #dadbd8);
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  text-transform: uppercase;
  fill: black;
  z-index: 1;
}

.logo,
.title {
  position: relative;
  margin-right: auto;
  cursor: pointer;
  svg {
    height: 100%;
  }
}

.logo {
  margin-left: 10px;
  svg {
    height: 20px;
    width: 20px;
  }
}

.title svg {
  position: absolute;
  transform: translateX(-100%);
}
