@import '~nicbase-default-theme/lib/variables';

.waypoint-tooltip {
  position: relative;
  width: 100%;
  text-align: initial;
  white-space: nowrap;

  .block {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: $spacing-large;
    grid-row-gap: $spacing-small;
  }

  .block-title {
    position: relative;
    font-weight: bold;
    color: $color-primary;
    margin: $spacing-small 0;
    column-span: all;
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-primary;
  }
  .block-label {
    color: $color-primary;
  }
  .name {
    margin-left: $spacing-small;
  }

  .deviation-red {
    color: $color-palette-red;
  }

  .deviation-yellow {
    color: $color-palette-yelloworange;
  }

  .deviation-green {
    color: $color-palette-green;
  }
}
