@import '~nicbase-default-theme/lib/variables.scss';

.wrapper {
  @apply flex flex-col ml-4;

  .iconContainer {
    @apply pb-4 border-l-2 border-dotted border-gray-400;

    &:last-child {
      @apply border-none;
    }

    .icon {
      @apply bg-white w-5 h-5 items-center rounded-full fill-primary;
      transform: translateX(-55%);
    }

    .circle {
      @apply w-4 h-4 mr-2 rounded-full flex-shrink-0 border-primary bg-white w-4 h-4 items-center rounded-full border-4;
      transform: translateX(-55%);
    }

    .container {
      @apply ml-12 relative;
      top: -20px;

      .removeWaypoint {
        @apply cursor-pointer ml-12 fill-primary opacity-0;
        transition: opacity 0.2s linear;
      }

      &:hover {
        .removeWaypoint {
          @apply opacity-100;
        }
      }
    }
  }
}
.switch {
  @apply mx-2;

  > span {
    @apply bg-primary;
  }
}
