@import '~nicbase-default-theme/lib/variables';

.titleContainer {
  display: flex;
  margin-bottom: $spacing-medium;
  margin-top: $spacing-medium;
}
.title {
  color: $color-primary;
  font-size: $font-size-headline1;
  border-bottom: 2px solid transparent;
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;

  &:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    content: ' ';
    background: linear-gradient(90deg, $color-primary, rgba($color-primary, 0.05));
  }
}
.iconContainer svg {
  fill: $color-primary;
  margin-right: $spacing-medium;
}
.titleText {
  font-weight: bold;
}
.editComponent {
  cursor: pointer;
  margin-left: auto;
}
.content {
  transition: max-height 0.5s linear;
  overflow-y: hidden;
  font-size: $font-size-small;
  max-height: 100%;

  span,
  li,
  ul,
  input {
    font-size: $font-size-small;
  }
}
