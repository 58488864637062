@import "~nicbase-default-theme/lib/variables";

/* The switch - the box around the slider */
.switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 0 2px 2px;
}

/* Hide default HTML checkbox */
.inputElement {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

/* The slider */
.slider {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 20px;
  background-color: #dadbd8;
  transition: 0.4s;

  &:hover {
    transform: scale(1.1);
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.inputElement:checked + .slider {
  background-color: $color-primary;
}

.inputElement:checked + .slider:before {
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.label {
  font-size: 1.4rem;
  margin-left: 4px;
}

.inputElement:disabled {
  ~ .label {
    color: #cccccc;
  }
  &:checked ~ .slider {
    background-color: #999999;
  }
}
