@import '~nicbase-default-theme/lib/variables';

.tour-duration-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .time {
    font-size: 18px;
    padding-bottom: $spacing-small;
    font-weight: $font-weight-headline1;
  }

  .description {
    font-weight: $font-weight-headline1;
  }
}
