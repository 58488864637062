@import "~nicbase-default-theme/lib/variables";

.tooltip {
  background: #ffffff !important;
  font-family: $font-family !important;
  font-size: $font-size-small !important;
  font-weight: normal !important;
  color: $color-text !important;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(153, 153, 153, 1) !important;
     -moz-box-shadow: 2px 2px 4px 0 rgba(153, 153, 153, 1) !important;
          box-shadow: 2px 2px 4px 0 rgba(153, 153, 153, 1) !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
  float: left !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important;
  opacity: 1 !important;

  &:after {
    border: none;
  }
}
