@import "~nicbase-default-theme/lib/variables";

.flyOutItem {
  padding: $spacing-medium;
  list-style-type: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  color: $color-primary;
  border-bottom: 1px solid $color-palette-grey20;
  white-space: nowrap;

  &.disabled {
    color: $color-palette-grey20;
  }

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    border-bottom: none;
  }
}
