@import './config';

@tailwind base;

html {
  font-size: $base-font-size;
}

html,
body {
  height: 100%;
  font-family: $font-family;
  color: $color-text;
  @apply overflow-x-hidden;
}

#root {
  font-size: $font-size-normal;
  height: 100%;
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: 22px;
  padding: 10px 0 14px 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

/* fix for linebreaks caused by tailwind reset (svg: block)*/
svg {
  display: inline-block;
}
/* tailwind style from style-guide */
@tailwind utilities;

/* custom utilities */
.centered {
  text-align: center;
}

.inline {
  display: inline-block;
}

.scale-on-hover {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
    animation-fill-mode: none;
  }
}

.scale-in {
  animation: scaleIn 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.8);
  }
  90% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.scale-in-from-zero {
  animation: scaleInFromZero 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes scaleInFromZero {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
