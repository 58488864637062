@import '~nicbase-default-theme/lib/variables.scss';

.arrival-deviation-container {
  font-weight: bold;

  .deviation-red {
    color: $color-palette-red;
  }

  .deviation-yellow {
    color: $color-palette-yelloworange;
  }

  .deviation-green {
    color: $color-palette-green;
  }

  .arrival-deviation-icon {
    margin-right: $spacing-small;
  }
}

.asset-offline-container {
  display: flex;
  flex: 1;
}

.asset-offline {
  display: flex;
  flex-basis: 60px;
  text-align: left;
  color: $color-palette-red;
  font-weight: bold;
}
