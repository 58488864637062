@import '~nicbase-default-theme/lib/variables.scss';

.asset {
  @apply my-4;

  &:hover {
    .removeAsset {
      opacity: 1;
    }
  }

  .removeAsset {
    @apply cursor-pointer ml-12;
    fill: $color-primary;
    transition: opacity 0.2s linear;
    opacity: 0;
  }
}
