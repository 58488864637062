@import '~nicbase-default-theme/lib/variables';

.button {
  background-color: transparent;
  height: 3.6rem;
  min-width: 9rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: $font-family;
  letter-spacing: 0;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  outline: none;
  transition: transform 100ms ease, background-color 400ms ease, color 400ms ease;
  color: $color-palette-black;
  padding: 0 1.2rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background: #e3e3e3;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background: #e3e3e3;
  }

  &.icon {
    min-width: 0;
  }

  > svg {
    width: 1em;
    font-size: 120%;
  }
}

.primary {
  color: $color-primary;
}

.raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: transparent;
  font-weight: 800;

  &:hover {
    background: transparent;
  }
}

.raised.primary {
  background: $color-primary;
  color: $color-palette-white;

  &:hover {
    background: $color-primary;
  }

  &:disabled,
  &:disabled:hover {
    color: $color-palette-grey40;
    background: #e3e3e3;
  }
}

.warning {
  color: $color-palette-red;

  svg {
    fill: currentColor;
  }

  &:hover {
    background: $color-palette-red;
    color: $color-palette-white;
  }
}
