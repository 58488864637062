.waypoint {
  margin-right: 20%;
  @apply text-gray-500 py-8 cursor-pointer;

  .waypoint__dot {
    @apply h-5 w-5 rounded-full text-white bg-gray-500 flex items-center justify-center;
  }

  .progress-line {
    @apply border-none border-2;
    border-left-style: dashed;
  }

  &.isReached {
    @apply text-primary;
    .waypoint__dot {
      @apply font-bold bg-primary shadow;
    }
    .isArrived,
    .isDeparted {
      border-left-style: solid;
      @apply border-primary;
    }
  }
}
