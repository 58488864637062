/**
 * Created by SHartmann on 09.02.2017.
 */
@import '~nicbase-default-theme/lib/variables';

$color: $color-primary;
$backgroundcolor: $color-palette-white;
$fontfamily: $font-family;
$fontsize: $font-size-small;
$spacing: $spacing-default;

.flow-root:before,
.flow-root:after {
  content: ' ';
  display: table;
}
.flow-root:after {
  clear: both;
}
.flow-root {
  *zoom: 1;
}

.left {
  float: left;
  margin-right: $spacing;
}

.nic-tooltip-data {
  font-family: $fontfamily;
  font-size: $fontsize;
  background: $backgroundcolor;
  color: $color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 170px;

  svg.label-icon {
    width: 1.8rem;
    fill: $color-primary;
  }
}

.bold {
  font-weight: bold;
}

.map-cell {
  text-align: left;
  font-size: inherit;
  width: 100%;
  color: $color;

  .map-inner-cell {
    padding-right: 10px;
  }
}

.padding-tooltip {
  padding: 5px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-4 {
  padding-top: 4px;
}

.padding-left {
  padding-left: 25px;
}

.padding-bottom-4 {
  padding-bottom: 4px;
}

hr {
  align-items: center;
  width: 90%;
  height: 1px;
  margin: 10px auto;
  background: $color;
}
