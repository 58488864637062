.mainNav {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.logo {
  margin: 0px 10px;

  svg {
    height: 55px;
    width: 55px;
  }
}

.welcome {
  font-size: 3rem;
  font-weight: 800;
  width: 200px;
  margin: 40px 10px;
}
