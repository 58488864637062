@import "~nicbase-default-theme/lib/variables";

.homelet {
  @apply m-5 bg-gray-400 h-auto rounded shadow-md;
}

.homeletContent {
  h2,
  h3,
  h4 {
    @apply uppercase;
    color: inherit;
  }
  h2 {
    font-size: 2.4rem;
    @apply text-2xl;
  }
  h3 {
    @apply text-lg;
  }
}

.badge {
  @apply inline-block rounded font-bold text-sm py-1 px-2 bg-primary text-white uppercase no-underline m-3;
}


// variants
.banner {
  @apply h-24 mb-1;
  background: linear-gradient($color-primary, $color-palette-darkblue);
}
.white {
  @apply bg-white text-black;
}
.blue {
  @apply text-white;
  background: linear-gradient($color-primary, $color-palette-darkblue);
}
