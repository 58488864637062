.wrapper {
  @apply my-0 mr-0 ml-3;

  .container {
    @apply pt-0 px-4 pb-4 border-l-2 border-gray-400 border-dashed cursor-pointer relative;
    min-height: 56px;

    &:last-of-type {
      border-left: none;
      margin-left: 3px;
      min-height: 0;
    }

    .number {
      @apply bg-gray-400 absolute flex text-white justify-center items-center rounded-full w-5 h-5 top-0;
      left: -12px;
    }
  }
}
