.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  @apply appearance-none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  @apply h-4 w-4 bg-primary cursor-pointer rounded-full;
}

.slider::-moz-range-thumb {
  @apply appearance-none h-4 w-4 bg-primary cursor-pointer rounded-full;
}

/** FF*/
.slider::-moz-range-progress {
  @apply bg-primary h-full rounded;
}
