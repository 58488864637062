.circleContainer {
  @apply relative h-32;

  &:before {
    @apply left-0 bottom-0 absolute border-l-2 border-dotted border-gray-400;
    content: '';
    height: 80%;
  }

  &:last-child {
    @apply h-6;
    &:before {
      border: none;
    }
  }

  .icon {
    @apply bg-white w-5 h-5 items-center rounded-full fill-primary;
    transform: translateX(-50%);
  }
  .selectedIcon {
    @apply bg-white w-5 h-5 items-center rounded-full fill-gray-400;
    transform: translateX(-50%);
  }

  .circle {
    @apply w-4 h-4 mr-2 rounded-full flex-shrink-0 border-primary bg-white items-center border-4;
    transform: translateX(-50%);
  }
  .selectedCircle {
    @apply w-4 h-4 mr-2 rounded-full flex-shrink-0 border-gray-400 bg-white items-center border-4;
    transform: translateX(-50%);
  }
}
