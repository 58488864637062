@keyframes faderIn {
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(0px, 0, 0);
  }
  100% {
    transform: none;
  }
}
.faderIn {
  animation-name: faderIn;
}

@keyframes faderOut {
  20% {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.faderOut {
  animation-name: faderOut;
}

.animate {
  animation-duration: 2000ms;
}

.toast-container {
  @apply p-2 rounded z-50 bg-white;
  top: 8.5em;
  right: 2em;

  &.red {
    @apply bg-red-500;
  }
}

.toast-progress {
  background: 'linear-gradient(to right, #0B76A5, #004864)';
}
