@import "~nicbase-default-theme/lib/variables";

/* Customize the label (the container) */
.checkbox {
  @apply relative flex items-center cursor-pointer select-none;
  padding: 2px 0 2px 2px;
}

/* Hide the browser's default checkbox */
.inputElement {
  @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

/* Create a custom checkbox */
.checkmark {
  @apply relative top-0 left-0 flex-shrink-0 rounded-sm bg-gray-300 mr-1 transform transition-all duration-400 ease-in-out hover:scale-110;
  height: 18px;
  width: 18px;
}

/* When the checkbox is checked, add a blue background */
.inputElement:checked ~ .checkmark {
  @apply bg-primary;
}
.inputElement:disabled {
  ~ .checkmark {
    @apply bg-gray-300;

    &:after {
      @apply border-gray-500;
    }
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  @apply absolute hidden border-white transform rotate-45;
  content: "";
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border-width: 0 2px 2px 0;
}

/* Show the checkmark when checked */
.inputElement:checked ~ .checkmark:after {
  @apply block;
}

.label {
  @apply text-base;
}

.bold {
  @apply font-bold;
}
