@import "~nicbase-default-theme/lib/variables";
$preferred-font: $font-family;
$color-primary: $color-primary;
$button-primary-color: $color-primary;
$nav-color-primary: #c7c9c8 !default;
$nav-color-accent: #004864 !default;
$nav-bar-icon-colour: $color-grey-lighter;
$nav-bar-height: 48px;
$nav-bar-width: 48px;
$nav-bar-border-size: 0;
$nav-bar-top-border-bottom-size: 4px;
$nav-bar-icon-height: 30px;
$edit-bar-height: 48px;
$options-bar-height: 32px;
$nav-bar-icon-height: 24px;
$header-bar-height: 48px;
$widget-icon-height: $options-bar-height * 0.7;

.hide-on-desktop {
  @include desktop {
    display: none;
  }
}

.hide-on-mobile {
  @include mobile {
    display: none;
  }
}

.hide-on-all {
  display: none;
}

.raised {
  font-family: $font-family !important;
  font-weight: $font-weight-bold !important;
}
