img,
video {
  &.media {
    max-width: 90%;
    max-height: 60%;
  }
}
@media only screen and (min-width: 768px) {
  img,
  video {
    &.media {
      margin-top: 25px;
      margin-left: 25px;
    }
  }
  .home-content-box {
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 1;

    .content {
      flex: 1;
      padding: 0 40px 0 40px;

      &.media-box-full {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  &.media {
    padding: 20px 0 0 15px;
  }
}
