.widget {
  @apply flex flex-col flex-1 text-sm bg-white overflow-hidden shadow-md sm:rounded-lg;
}

.widgetHeader {
  @apply relative flex items-center gap-2 p-2 border-b-2 border-primary text-base;
}

.widgetIcon svg {
  @apply h-6 w-6;
}

.widgetName {
  @apply flex-1 text-primary text-lg font-bold;
}

.settingsToggle {
  @apply cursor-pointer fill-primary;
}

.deleteWidget {
  @apply cursor-pointer fill-primary;
}

.widgetContent {
  @apply relative flex flex-col flex-1 p-2 min-h-0;
}

.editActive {
  &:after {
    @apply absolute top-0 left-0 w-full h-full z-20 bg-black bg-opacity-40;
    content: '';
  }
}

.noBorder {
  @apply p-0;
}

.settingsContent {
  @apply text-lg;
}

.widgetConnectStatus {
  @apply fill-gray-500;

  &.connected {
    @apply fill-primary;
  }
}

.kasasi-dialog-overlay .kasasi-dialog .content-container .delete-widget-dialog-content {
  font-size: 140%;
}
