@import "~nicbase-default-theme/lib/variables.scss";

.title {
  margin: $spacing-xlarge 0;
  font-weight: normal;
  font-size: $font-size-large;
}
.footer {
  padding: $spacing-medium;
  border-top: 1px solid $color-primary;
  display: flex;
  justify-content: flex-end;
}

.assetAssignment {
  max-width: 700px;
  margin: auto $spacing-medium;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: $color-palette-white;
  font-family: $font-family;
  color: $color-primary;
  animation: fadeIn forwards 400ms;
}

.header {
  display: flex;
  align-items: center;
  padding: $spacing-medium;
  font-weight: bold;
  position: relative;

  &:after {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    content: " ";
    background: linear-gradient(90deg, #002b3b, rgba(0, 109, 151, 0.7) 60%, rgba(255, 2525, 255, 0));
  }
}

.content {
  padding: $spacing-large $spacing-xlarge;
}

.assignmentForm {
  margin: $spacing-large 0;
}
.assetSelection {
  max-width: 300px;
}
.selectionHint {
  font-size: $font-size-small;
}

.spacer {
  flex: 1;
}

.confirmationContainer {
  color: $color-primary;
  min-width: 33vw;
  background-color: $color-palette-white;
  box-shadow: 2px 2px 6px rgba(150, 150, 150, 0.5), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 3px;

  .confirmTitle {
    padding: $spacing-medium;
    font-weight: bold;
    position: relative;

    &:after {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 1px;
      content: " ";
      background: linear-gradient(90deg, #002b3b, rgba(0, 109, 151, 0.7) 60%, rgba(255, 2525, 255, 0));
    }
  }
  .contentContainer {
    text-align: center;
    padding: $spacing-xxlarge;

    .tick {
      padding-bottom: $spacing-xxlarge;

      svg {
        fill: $color-primary;
        height: 100px;
        width: 100px;
      }
    }

    .subtitle {
      font-size: $font-size-large;
      padding-bottom: $spacing-xxlarge;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
  }
  to {
    opacity: 1;
    transform: scale(1);
    transform-origin: center;
  }
}
