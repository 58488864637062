@import '../../config';

$left-nav-bar-mobile-width: 100px;
$right-nav-bar-mobile-width: 160px;

.nav-bar {
  background: $nav-color-primary;
  z-index: 30;
  position: relative;
  display: flex;

  button {
    background-color: transparent;
    border: none;
  }

  .icon {
    text-align: center;

    &:hover {
      background-color: rgba($nav-color-accent, 0.5);
    }

    svg {
      height: $nav-bar-icon-height;
      width: $nav-bar-icon-height;
      cursor: pointer;
      fill: $nav-bar-icon-colour;
      fill: var(--color-icon-nav-bar-left, $nav-bar-icon-colour);
    }
  }

  &.nav-bar-top {
    @apply shadow-md;
    min-height: $nav-bar-height;
    border-bottom: $nav-bar-top-border-bottom-size solid $nav-color-accent;
    border-bottom: $nav-bar-top-border-bottom-size solid var(--color-background-nav-bar-left, $nav-color-accent);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $nav-color-primary;
    background: var(--color-background-nav-bar-top, $nav-color-primary);

    &.nav-bar-top-mobile {
      border-bottom: none;
      flex: 1;
      width: 100%;
      position: fixed;
    }

    &.nav-bar-top-desktop {
      padding: 0 10px;
    }

    .portal-logo,
    .nicplace-logo {
      height: 30px;
      width: auto;
    }

    .nav-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    svg.icon {
      margin: 0 14px;
      padding: 0;
      fill: $nav-color-accent;
      fill: var(--color-icon-nav-bar-top, $nav-color-accent);
    }
  }

  &.nav-bar-left {
    @apply shadow-md;
    flex-direction: column;
    background: $nav-color-accent;
    background: var(--color-background-nav-bar-left, $nav-color-accent);
    flex: 0 0 $nav-bar-width;
    padding-top: ($edit-bar-height);

    .icon {
      padding: 15px 0;
      position: relative;

      &:hover {
        > svg {
          fill: white;
          transform: scale(1.03);
        }
      }

      &.hidden {
        display: none;
      }

      $arrow-height: 16px;
      .arrow {
        width: $arrow-height;
        height: $arrow-height;
        position: absolute;
        top: 20px;
        left: 40px;
        clip: rect((-1.5 * $arrow-height) (1.5 * $arrow-height) (1.5 * $arrow-height) (0.5 * $arrow-height));

        &:after {
          @apply shadow-md;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          transform: rotate(-45deg);
          background-color: $nav-color-accent;
          background-color: var(--color-background-nav-bar-left, $nav-color-accent);
        }
      }
    }

    .desktop-mode-toggle {
      border-bottom: none;
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &.tablet,
    &.mobile {
      background: $nav-color-primary;
      top: 0;
      position: fixed;
      bottom: 0;
      width: $left-nav-bar-mobile-width;
      left: -100%;
      transition: 500ms;

      .arrow {
        display: none;
      }

      &.shown {
        left: 0;
      }

      .icon {
        padding: 8px 0;
        border: none;

        &:first-of-type {
          border-top: none;
        }

        svg {
          width: 38px;
          height: 38px;
          fill: $nav-color-accent;
        }
      }
    }
  }

  &.nav-bar-right {
    @apply shadow-md;
    background: $nav-color-primary;
    position: fixed;
    bottom: 0;
    right: -100%;
    top: 0;
    width: $right-nav-bar-mobile-width;
    transition: 500ms;

    &.shown {
      right: 0;
    }

    .menu-icon {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: $nav-bar-height;
      font-size: 12pt;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;

      svg {
        fill: $nav-color-accent;
        margin-right: 4px;
      }

      .text {
        color: $nav-color-accent;
      }
    }

    .menu-items-container {
      margin-top: $nav-bar-height;
      overflow-y: scroll;
      word-break: break-all;
    }
  }
}
