@import "~nicbase-default-theme/lib/variables";

.tooltip-poi-data {
  color: $color-primary;
  font-size: $font-size-small;

  section {
    display: grid;
    grid-template-columns: 24px 1fr;
    border-bottom: 1px solid $color-primary;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .name {
    font-weight: bold;
  }
}
