@import '../config';

.kasasi-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  animation: fadeIn 0.2s ease;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes fadeIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .kasasi-dialog {
    @apply flex flex-col rounded-lg bg-white shadow-xl;
    max-height: 90vh;
    max-width: 80vw;
    min-width: 20vw;
    -webkit-overflow-scrolling: touch;
    outline: none;
    overflow: hidden;
    animation: dialogFade 0.2s ease-in-out;
    animation-fill-mode: forwards;
    font-size: $font-size-small;

    @keyframes dialogFade {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .action-button {
      margin-left: 14px;
    }

    .confirm-button {
      display: inline-block;
    }

    .cancel-button {
      display: inline-block;
    }

    .title-container {
      @apply relative flex flex-col justify-center p-2 border-b-2 border-primary bg-white;
      flex: 1 0 auto;

      .title {
        color: $color-primary;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
      }
    }

    .content-label {
      padding: $spacing-large $spacing-large $spacing-medium;
      display: flex;
      flex: 1 0 auto;

      .icon {
        width: 100%;
        color: $color-primary;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding-top: 5px;
        padding-left: 5px;
      }
      .spacer {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba($color-primary, 1), rgba($color-accent, 0.1));
      }
    }

    .content-container {
      padding: $spacing-medium $spacing-large $spacing-large;
      overflow-y: auto;
    }

    .details {
      width: 150px;
    }

    .actions-container {
      @apply flex justify-end p-2 border-t border-primary bg-white;
      flex: 1 0 auto;
    }
  }
  .detail-dialog {
    max-height: 90vh;
    max-width: 90vw;
    min-width: 20vw;
  }
}
