input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  @apply appearance-none w-4 h-4 rounded-full cursor-pointer;
}

input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  pointer-events: all;
  @apply appearance-none w-4 h-4 rounded-full cursor-pointer;
}

.minInput {
  transform: translate(-50%, calc(1.2rem + 8px));
  @apply absolute;
}

.maxInput {
  transform: translate(50%, calc(-100% - 8px));
  @apply absolute;
}

.topInputMarker {
  @apply w-0 h-0 absolute border-transparent border-l-8 border-r-8 border-t-8 bottom-0 right-1/2 transform translate-x-1/2 translate-y-full;
  border-top-color: #D6D6D6;
}

.bottomInputMarker {
  @apply w-0 h-0 absolute border-transparent border-l-8 border-r-8 border-b-8 top-0 right-1/2 transform translate-x-1/2 -translate-y-full;
  border-bottom-color: #D6D6D6;
}

