.timeline-item {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
}

.timeline-item:first-child .timeline-item__progress-line:first-child,
.timeline-item:last-child .timeline-item__progress-line:last-child {
  display: none;
}

.timeline-item__progress-line {
  position: absolute;
  left: 50%;
  height: 50%;
  border-left: 4px solid #95989a;
  transform: translateX(-50%);

  &:first-child {
    top: 0;
  }
  &:last-child {
    bottom: 0;
  }
}

.timeline-item__left {
  display: flex;
  flex: 1;
  margin-right: 25px;
  text-align: right;
  justify-content: flex-end;
}

.timeline-item__center {
  z-index: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item__right {
  display: flex;
  flex: 1;
  margin-left: 25px;
  text-align: left;
  justify-content: flex-start;
}
