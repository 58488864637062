@import '~nicbase-default-theme/lib/variables';

.times-and-distance-container {
  display: flex;
  flex-direction: column;
  color: $color-primary;

  .distance,
  .arrow {
    display: flex;
    align-items: center;
  }

  .tour-times-container,
  .tour-running-statistics-container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    padding: $spacing-large 0;
  }

  .end-time-container,
  .start-time-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.no-info {
      .time,
      .date {
        visibility: hidden;
      }
    }

    .time {
      font-size: 18px;
      padding-bottom: $spacing-small;
      font-weight: $font-weight-headline1;
    }

    .date {
      padding-bottom: $spacing-medium;
    }

    .description {
      font-weight: $font-weight-headline1;
    }
  }

  .tour-distance-container {
    display: flex;
    flex: 1;
  }
}
