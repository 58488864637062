@import '~nicbase-default-theme/lib/variables.scss';

.badge {
  border-radius: 3px;
  padding: 5px;
  font-weight: bold;

  &.badge--red {
    color: $color-palette-white;
    background: $color-palette-red;
  }

  &.badge--yellow {
    color: $color-palette-white;
    background: $color-palette-yelloworange;
  }

  &.badge--green{
    color: $color-palette-white;
    background: $color-palette-green;
  }
}
