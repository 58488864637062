.help-button {
  @apply relative cursor-pointer;

  .help-menu {
    visibility: hidden;
    opacity: 0;
    top: calc(100% + 8px);
    left: -50%;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    @apply rounded transition-all ease-in duration-500 bg-white absolute text-center;
  }

  &:hover .help-menu {
    @apply visible opacity-100 delay-500;
  }

  ul,
  li {
    @apply list-none;
  }
  li {
    @apply border-b border-gray-300;

    &:hover {
      @apply bg-gray-200;
    }

    &:last-child {
      @apply border-none;
    }
  }

  .arrow {
    @apply relative bg-white;

    &:after {
      border: solid transparent;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      content: ' ';
      margin-left: -8px;
      @apply bottom-full left-1/2 h-0 w-0 absolute pointer-events-none border-8;
    }
  }
}
