@import '~nicbase-default-theme/lib/variables';
$dualListBackgroundColour: $color-palette-grey20;
$listItemTextColour: $color-primary;
$listItemSelectedBackgroundColour: $color-primary;
$listItemSelectedTextColour: $color-palette-white;

.dual-list-individually-selectable,
.dual-list-extended {
  text-align: left;
  margin-bottom: 15px;
  font-size: 1.2rem;

  .selected-assets {
    .individual-selection-buttons-container {
      @apply flex gap-2;

      .individual-color-button {
        background-color: $color-palette-grey20;
        box-shadow: none;
        font-weight: 800;
      }
      .inactive-color-button {
        color: $color-primary;
      }
    }
  }

  .dual-list-individually-selectable-container,
  .dual-list-extended-container {
    display: flex;
    overflow: hidden;
    transition: max-height 0s linear;
    max-height: 100%;

    &.hide-dual-list {
      max-height: 0;
    }
  }
}

.dual-list {
  display: flex;
  flex-direction: row;
  background: transparent;
  align-items: stretch;
  width: 100%;
  font-size: 1.2rem;

  .list-area-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .assigned-info-container {
      padding: $spacing-small 0;
      color: $listItemTextColour;
      display: flex;
      justify-content: space-between;
    }

    .filter {
      @apply relative border-b border-primary;
      background: $dualListBackgroundColour;
      padding-left: $spacing-medium;

      .close-filter {
        position: absolute;
        right: $spacing-small;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }

      input {
        padding: $spacing-medium 0 $spacing-medium 0;
        background: $dualListBackgroundColour;
        color: $listItemTextColour;
        border: none;
        width: 100%;

        &.search-icon-as-background {
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.9,18.66,14.4,14.16a6,6,0,0,0,1.33-3.74,6.07,6.07,0,1,0-6.07,6.07,6,6,0,0,0,3.74-1.33l4.49,4.49a.7.7,0,0,0,1-1ZM5,10.42a4.66,4.66,0,1,1,4.66,4.66A4.67,4.67,0,0,1,5,10.42Z" style="fill:#024965"/><path d="M10.8,7.72a.5.5,0,0,1-.27-.08,2.11,2.11,0,0,0-1.64-.1.5.5,0,0,1-.35-.94,3.1,3.1,0,0,1,2.53.19.5.5,0,0,1-.27.92Z" style="fill:#024965"/></svg>')
            no-repeat center right 2px;
        }

        &::placeholder {
          color: $color-grey-light;
          opacity: 1;
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $color-grey-light;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $color-grey-light;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $color-grey-light;
          opacity: 1;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: $color-grey-light;
          opacity: 1;
        }

        &:focus {
          outline: none;
        }
      }
      svg {
        fill: $color-primary;
      }
    }

    .list-container {
      background: $dualListBackgroundColour;
      @apply min-w-96 h-56 overflow-y-auto;

      .list-item {
        cursor: pointer;
        padding: $spacing-small $spacing-large $spacing-small $spacing-large;
        color: $listItemTextColour;
        word-break: break-all;

        &.disabled {
          cursor: default;
        }

        &.selected {
          background: $listItemSelectedBackgroundColour;
          color: $listItemSelectedTextColour;
        }
      }
    }
  }

  .select-buttons {
    @apply flex flex-col justify-center items-center p-1 gap-1;
    flex: 0.1 1 0;

    .select-button {
      box-shadow: none;

      &[disabled] {
        background-color: transparent;
      }
      &[disabled] svg {
        fill: $color-grey-light;
      }
    }
  }
}
