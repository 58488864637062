@import '~nicbase-default-theme/lib/variables';

.noDataMessage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
  color: $color-primary;

  &.hidden {
    visibility: hidden;
  }

  .noDataMessage-Message {
    font-size: $font-size-large;
    margin-bottom: 10px;
  }

  .noDataMessage-Reasons {
    font-size: $font-size-small;
  }

  .noDataMessage-ReasonsParagraph {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .noDataMessage-ReasonsList {
    list-style: disc;
    margin-left: 20px;
  }
}
