@import '~nicbase-default-theme/lib/variables';

.tour-info-block {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;

  svg {
    fill: $color-primary;
  }

  .gradient-divider-bottom {
    position: relative;

    &:before {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      content: ' ';
      background: linear-gradient(90deg, $color-primary, rgba($color-primary, 0.05));
    }
  }

  .asset-offline-container {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
  }

  .asset-offline {
    display: flex;
    flex-basis: 60px;
    text-align: left;
    color: $color-palette-red;
    font-weight: bold;
  }

  .tour-times-and-distance-info {
    padding: $spacing-large 0 $spacing-large $spacing-xlarge;
  }

  .tour-alarms {
    padding: $spacing-large 0 $spacing-large $spacing-xlarge;
    display: inline-flex;
    min-height: 2 * $spacing-large;

    svg {
      height: $font-size-headline3;
    }
  }

  .tour-not-started {
    padding: $spacing-xlarge 0;
    font-weight: bold;
    color: $color-primary;
    justify-content: center;
    display: flex;
    font-size: 18px; 
  }
}

